<template>
  <div class="mt-1 z-40 absolute ml-4 px-8 py-6 base-bg rounded w-80 zoom-big-enter">
    <div class="relative">
      
      <closeModal @closeModal="closeModal"/>

      <h2 class="text-black font-sans font-bold antialiased text-xl mb-2">
        Filter Removed Videos
      </h2>
      <p class="text-black font-sans antialiased text-sm mb-3">
        Specifically filter for videos that have been removed or that remain online.
      </p>
      <div 
        class="mb-4 border rounded base-border-color px-2 py-4 cursor-pointer"
        @click="setRemovedItems(items[0])"
        :class="{ 
          'bg-white text-black':  removedItems.indexOf(items[0]) != -1, 
          'disabled-text-grey': removedItems.indexOf(items[0]) == -1
        }"
      >
        <p class="flex items-center text-sm font-medium"> 
          <camera class="mr-2"/>
          <span> Show videos that are online </span>
        </p>
      </div>
      <div 
        class="mb-4 border rounded base-border-color px-2 py-4 cursor-pointer"
        @click="setBothItems"
        :class="{ 
          'disabled-text-grey':  removedItems.indexOf(items[1]) == -1 && removedItems.indexOf(items[2]) == -1, 
          'bg-white text-black': removedItems.indexOf(items[1]) != -1 || removedItems.indexOf(items[2]) != -1
        }"
      >
        <p class="flex items-center text-sm font-medium"> 
          <remove class="mr-2"/>
          <span> Show removed videos </span>
        </p>
      </div>
      <div 
        class="mb-4 ml-12 border rounded base-border-color px-4 py-2 cursor-pointer half-rectangle-bg"
        @click="setRemovedItems(items[1])"
        :class="{ 
          'bg-white text-black':  removedItems.indexOf(items[1]) != -1, 
          'disabled-text-grey': removedItems.indexOf(items[1]) == -1
        }"
      >
        <p class="flex items-center text-sm font-medium"> Removed by YouTube </p>
      </div>
      <div 
        class="mb-4 ml-12 border rounded base-border-color px-4 py-2 cursor-pointer half-rectangle-big-bg"
        @click="setRemovedItems(items[2])"
        :class="{ 
          'bg-white text-black':  removedItems.indexOf(items[2]) != -1, 
          'disabled-text-grey': removedItems.indexOf(items[2]) == -1
        }"
      >
        <p class="flex items-center text-sm font-medium"> Removed by Owner </p>
      </div>
    </div>
  </div>
</template>

<script>
  import camera from '@/components/icons/camera'
  import remove from '@/components/icons/remove'
  import closeModal from '@/components/CloseModal'
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    components: {
      camera,
      remove,
      closeModal
    },
    data(){
      return {
        items: ['show_online', 'show_deleted_by_yt', 'show_deleted_by_owner']
      }
    },
    computed:{
      ...mapGetters({
        removedItems: 'search/removedItems'
      })
    },
    methods: {
      ...mapMutations({
        setRemovedTwoItems: 'search/setRemovedTwoItems',
        setRemovedItems: 'search/setRemovedItems'
      }),
      setBothItems(){
        this.setRemovedTwoItems([this.items[1], this.items[2]])
      },
      closeModal(){
        this.$emit('closeModal')
      }
    }
  }
</script>

<style lang="scss" scoped>
.half-rectangle-big-bg,
.half-rectangle-bg{
  position: relative;
  &:after{
    content: '';
    position: absolute;
    left: -2rem;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.half-rectangle-bg{
  &:after{
    top: 0;
    width: 1rem;
    height: 1rem;
    background-image: url('/images/rectangle-half.svg');
  }
}
.half-rectangle-big-bg{
  &:after{
    top: -1rem;
    width: 2rem;
    height: 2rem;
    background-image: url('/images/rectangle-half-big.svg');
  }
}
</style>