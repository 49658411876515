var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-1 z-40 absolute ml-4 px-8 py-6 base-bg rounded w-80 zoom-big-enter"},[_c('div',{staticClass:"relative"},[_c('closeModal',{on:{"closeModal":_vm.closeModal}}),_c('h2',{staticClass:"text-black font-sans font-bold antialiased text-xl mb-2"},[_vm._v(" Filter Removed Videos ")]),_c('p',{staticClass:"text-black font-sans antialiased text-sm mb-3"},[_vm._v(" Specifically filter for videos that have been removed or that remain online. ")]),_c('div',{staticClass:"mb-4 border rounded base-border-color px-2 py-4 cursor-pointer",class:{ 
        'bg-white text-black':  _vm.removedItems.indexOf(_vm.items[0]) != -1, 
        'disabled-text-grey': _vm.removedItems.indexOf(_vm.items[0]) == -1
      },on:{"click":function($event){return _vm.setRemovedItems(_vm.items[0])}}},[_c('p',{staticClass:"flex items-center text-sm font-medium"},[_c('camera',{staticClass:"mr-2"}),_c('span',[_vm._v(" Show videos that are online ")])],1)]),_c('div',{staticClass:"mb-4 border rounded base-border-color px-2 py-4 cursor-pointer",class:{ 
        'disabled-text-grey':  _vm.removedItems.indexOf(_vm.items[1]) == -1 && _vm.removedItems.indexOf(_vm.items[2]) == -1, 
        'bg-white text-black': _vm.removedItems.indexOf(_vm.items[1]) != -1 || _vm.removedItems.indexOf(_vm.items[2]) != -1
      },on:{"click":_vm.setBothItems}},[_c('p',{staticClass:"flex items-center text-sm font-medium"},[_c('remove',{staticClass:"mr-2"}),_c('span',[_vm._v(" Show removed videos ")])],1)]),_c('div',{staticClass:"mb-4 ml-12 border rounded base-border-color px-4 py-2 cursor-pointer half-rectangle-bg",class:{ 
        'bg-white text-black':  _vm.removedItems.indexOf(_vm.items[1]) != -1, 
        'disabled-text-grey': _vm.removedItems.indexOf(_vm.items[1]) == -1
      },on:{"click":function($event){return _vm.setRemovedItems(_vm.items[1])}}},[_c('p',{staticClass:"flex items-center text-sm font-medium"},[_vm._v(" Removed by YouTube ")])]),_c('div',{staticClass:"mb-4 ml-12 border rounded base-border-color px-4 py-2 cursor-pointer half-rectangle-big-bg",class:{ 
        'bg-white text-black':  _vm.removedItems.indexOf(_vm.items[2]) != -1, 
        'disabled-text-grey': _vm.removedItems.indexOf(_vm.items[2]) == -1
      },on:{"click":function($event){return _vm.setRemovedItems(_vm.items[2])}}},[_c('p',{staticClass:"flex items-center text-sm font-medium"},[_vm._v(" Removed by Owner ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }